import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { FlexWrapperPadded, ThreeQuartersCenterCentered } from '../shared'
// import Headline from '../Atoms/Headline'

const Quote = styled.h2`
	${tw`text-4xl lg:text-5xl font-black relative`};
	&:before {
		content: '“';
		font-family: helvetica;
		font-weight: bold;
		text-align: center;
		font-size: 96px;
		line-height: 48px;
		display: block;
	}
`
const Source = styled.h3`
	${tw`font-bold text-2xl uppercase mt-4 md:mt-8 opacity-50 block`};
	color: inherit;
`

export default function Text(props) {
	const {
		quote,
		sourceArticle,
		pressSelector,
		otherSource,
		hyperlink,
		style
	} = props?.matrixBuilder

	// function Notification({ text, state }) {
	// 	return (
	// 		<div>
	// 			{{
	// 				info: <Info text={text} />,
	// 				warning: <Warning text={text} />,
	// 				error: <Error text={text} />,
	// 			}[state]}
	// 		</div>
	// 	);
	// }

	const isPressSelector = pressSelector && pressSelector.length
	const isSourceArticle = sourceArticle && sourceArticle.length

	const thesourcetitle = isPressSelector ? pressSelector[0].title : null

	const alsosourcetitle =
		thesourcetitle || sourceArticle[0].pressSelector[0].title

	// const thesource = sourceArticle
	// 	? sourceArticle[0].pressSelector[0].title
	// 	: otherSource

	const sourcelink = sourceArticle
		? sourceArticle[0].externalLink.url
		: hyperlink.link

	return (
		quote && (
			<FlexWrapperPadded data-blocktheme={style}>
				<ThreeQuartersCenterCentered>
					<Quote>{quote}</Quote>

					{sourcelink ? (
						<Source as="a" href={sourcelink} target="_blank">
							{alsosourcetitle}
						</Source>
					) : (
						<Source>{alsosourcetitle}</Source>
					)}
				</ThreeQuartersCenterCentered>
			</FlexWrapperPadded>
		)
	)
}

export const query = graphql`
	fragment MatrixBuilderTestimonialQuery on Craft_MatrixBuilderTestimonial {
		quote
		sourceArticle {
			title
			... on Craft_Press {
				externalLink {
					link
					url
				}
				pressSelector {
					title
				}
			}
		}
		pressSelector {
			title
			textLogo
		}
		hyperlink {
			link
		}
		otherSource
		style
	}
`
