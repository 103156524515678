import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import GridCard from '../Atoms/GridCard'

const GridWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
	grid-column-gap: 20px;
	grid-row-gap: 20px;
	justify-items: stretch;
	align-items: stretch;
	position: relative;
	margin: 20px 0;

	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	.go-item {
		${tw`min-h-300 bg-yellow-dark relative flex flex-col`};
		background: url('https://placehold.it/600x400');
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	@media (min-width: 768px) {
		.x-go-1 {
			grid-area: 1 / 1 / 5 / 2;
		}

		.x-go-2 {
			grid-area: 1 / 2 / 3 / 3;
		}

		.x-go-3 {
			grid-area: 3 / 2 / 5 / 3;
		}

		.x-go-5 {
			grid-area: 1 / 3 / 4 / 4;
		}

		.x-go-6 {
			grid-area: 4 / 3 / 7 / 4;
		}

		.x-go-4 {
			grid-area: 5 / 1 / 7 / 3;
		}

		.x-go-7 {
			grid-area: 7 / 1 / 11 / 2;
		}

		.x-go-8 {
			grid-area: 7 / 2 / 9 / 4;
		}

		.x-go-9 {
			grid-area: 9 / 2 / 11 / 3;
		}

		.x-go-10 {
			grid-area: 9 / 3 / 11 / 4;
		}

		.x-6-1 {
			grid-area: 1 / 1 / 3 / 2;
		}

		.x-6-2 {
			grid-area: 1 / 2 / 2 / 4;
		}

		.x-6-3 {
			grid-area: 2 / 2 / 3 / 3;
		}

		.x-6-4 {
			grid-area: 2 / 3 / 3 / 4;
		}

		.x-6-5 {
			grid-area: 3 / 1 / 4 / 3;
		}

		.x-6-6 {
			grid-area: 3 / 3 / 4 / 4;
		}
	}
`

const GridListThree = ({ items, gridcount, gridrows, ...props }) => {
	const gridprefix = 'x'

	const RevWrapper = styled(GridWrapper)`
		grid-template-rows: repeat(${gridrows || 6}, 1fr);
	`

	return (
		<RevWrapper>
			{items.map((entry, i) => (
				<div
					key={i}
					className={
						'go-item ' + gridprefix + '-' + (gridcount || 'go') + '-' + (i + 1)
					}
				>
					<GridCard entry={entry} imageObject={entry.primaryThumb} />
				</div>
			))}
		</RevWrapper>
	)
}

export default GridListThree
