import React from 'react'
import { graphql } from 'gatsby'
import CareersList from '../Prebuilt/Careers'

export default function Careers(props) {
	const { id, style } = props?.matrixBuilder

	return <CareersList style={style} />
}

export const query = graphql`
	fragment MatrixBuilderCareersQuery on Craft_MatrixBuilderCareers {
		id
		style
	}
`

// <pre>{JSON.stringify(style, null, 2)}</pre>
