import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Headline from '../Atoms/Headline'
import Img from 'gatsby-image'
import { FlexWrapper, OneThird } from '../shared'

// const Box = styled.div`
// 	padding: 20px;
// 	background: lemonchiffon;

// 	pre {
// 		white-space: pre-wrap;
// 	}
// `

// const FlexWrapper = styled.div`
// 	${tw`flex flex-wrap -mx-3`};
// `

// const OneThird = styled.div`
// 	${tw`w-full md:w-1/3 px-3 mb-6`};
// `

const AltThird = styled(OneThird)`
	${tw`mb-0`};
`

const Instablock = ({ style, ...props }) => {
	const data = useStaticQuery(
		graphql`
			query {
				allInstaNode(limit: 3) {
					edges {
						node {
							id
							# likes
							# comments
							# mediaType
							# preview
							# original
							timestamp
							caption
							localFile {
								childImageSharp {
									# fixed(width: 600, height: 600) {
									# 	...GatsbyImageSharpFixed
									# }
									fluid(maxWidth: 600) {
										# ...GatsbyImageSharpFluid_withWebp_tracedSVG
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}
				}
			}
		`
	)

	const instaindex = data.allInstaNode.edges && (
		<div data-blocktheme={style || 'primary'}>
			<FlexWrapper>
				{data.allInstaNode.edges.map((edge, i) => (
					<AltThird>
						<a href={'https://www.instagram.com/p/' + edge.node.id}>
							<Img key={i} fluid={edge.node.localFile.childImageSharp.fluid} />
						</a>
					</AltThird>
				))}
			</FlexWrapper>
		</div>
	)

	return instaindex
}

export default Instablock
