import React from 'react'
import styled from 'styled-components'
import TextTruncate from 'react-text-truncate'

const Headline = ({ titleColor, truncate, text, sizing, level, ...props }) => {
	const Title = styled.h2`
		${tw`leading-none font-graphik font-bold relative`};
		margin: ${props.margin || '0 0 2rem 0'};
	`

	// PROPS: FITTY ? TRUNCATE ? MARGINS ?

	const sizingclasses = props.micro
		? ' text-xl md:text-2xl lg:text-3xl'
		: props.mini
		? ' text-2xl md:text-3xl lg:text-4xl'
		: props.small
		? ' text-3xl md:text-4xl lg:text-5xl'
		: props.medium
		? ' text-4xl md:text-5xl lg:text-6xl'
		: ' text-5xl md:text-6xl lg:text-8xl'

	return (
		<Title className={sizingclasses} as={level || 'h2'}>
			{props.children}
		</Title>
	)
}
export default Headline

// ${tw`${props.margin || 'mb-8'}`};

// const sizingclasses =
// sizing === 'sm'
// 	? text.length > 10
// 		? ' text-2xl md:text-3xl lg:text-4xl'
// 		: ' text-3xl md:text-4xl lg:text-5xl'
// 	: text.length > 10
// 	? ' text-4xl md:text-5xl lg:text-6xl'
// 	: ' text-5xl md:text-6xl lg:text-8xl'
