import React from 'react'
import { graphql } from 'gatsby'
import GridList from '../Clean/GridList'

export default function WorkSelector(props) {
	const { workSelections } = props?.matrixBuilder

	return (
		<GridList
			items={workSelections}
			gridcount={workSelections.length}
			gridrows={3}
		/>
	)
}

export const query = graphql`
	fragment MatrixBuilderWorkSelectionsQuery on Craft_MatrixBuilderWorkSelections {
		workSelections {
			id
			... on Craft_Work {
				__typename
				title
				uri
				primaryThumb {
					url
					focalPoint {
						x
						y
					}
				}
				headline
			}
		}
	}
`
