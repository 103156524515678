import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Link from '../Atoms/Link'
import { FlexWrapperPadded, ThreeQuartersCenterCentered } from '../shared'

export default function CallToAction(props) {
	const { cta, style } = props?.matrixBuilder

	const linkObject = cta && cta[0].button.url ? cta[0] : null

	return (
		linkObject && (
			<FlexWrapperPadded data-blocktheme={style || 'primary'}>
				<ThreeQuartersCenterCentered>
					<Link linkObject={linkObject} />
				</ThreeQuartersCenterCentered>
			</FlexWrapperPadded>
		)
	)
}

export const query = graphql`
	fragment MatrixBuilderCallToActionQuery on Craft_MatrixBuilderCallToAction {
		id
		cta {
			button {
				target
				entry {
					uri
					id
				}
				url
				text
				customText
			}
			buttonSize
			buttonIcon
			buttonStyle
		}
		style
		hideOnMobile
	}
`
