import React from 'react'
import styled from 'styled-components'

export const InputWrapper = styled.div`
	${tw`w-full px-3 mb-6 md:mb-8`};
`

export const StyledInput = styled.input`
	${tw`appearance-none border-b border-b-2 border-grey-light bg-transparent w-full text-grey-darker py-1 px-2 leading-tight focus:outline-none focus:border-grey-darker rounded-none`};
`

export const StyledTextArea = styled.textarea`
	${tw`w-full shadow-inner p-4 appearance-none outline-none focus:outline-none`};
`

export const StyledLabel = styled.label`
	${tw`font-medium text-xl md:text-2xl mb-2 block`};
`

export const FlexWrapper = styled.div`
	${tw`flex flex-wrap -mx-3`};
`

export const FlexWrapperPadded = styled(FlexWrapper)`
	${tw`py-16`};
`

export const FlexWrapperPaddedNotFirst = styled(FlexWrapperPadded)`
	&:first-of-type {
		padding-top: 0;
	}
`

export const OneThird = styled.div`
	${tw`w-full md:w-1/3 px-3 mb-6`};
`

export const OneHalf = styled.div`
	${tw`w-full md:w-1/2 px-3`};
`

export const TwoThirds = styled.div`
	${tw`w-full sm:w-5/6 md:w-2/3 px-3`};
`

export const TwoThirdsCenter = styled(TwoThirds)`
	${tw`mx-auto`};
`

export const ThreeQuarters = styled.div`
	${tw`w-full md:w-3/4 px-3`};
`

export const ThreeQuartersCenter = styled(ThreeQuarters)`
	${tw`mx-auto`};
`

export const ThreeQuartersCenterCentered = styled(ThreeQuartersCenter)`
	${tw`text-center`};
`

export const Full = styled.div`
	${tw`w-full px-3`};
`

export const OuterWidth = styled.div`
	${tw`px-6 md:px-12`};
`

const HoverLink = styled.a`
	${tw`no-underline`};
	transition: 200ms all ease-in-out;
`
// NEED TO DIFFERENTIATE INTERNAL LINKS TOO

export const TitleLink = styled(HoverLink)`
	${tw`w-full text-black hover:text-orange block group`};

	&:hover p {
		transition: 200ms all ease-in-out;
		${tw`opacity-50`};
	}
`
