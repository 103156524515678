import React from 'react'
import styled from 'styled-components'

// rules only show up if `rules == true`

const Text = ({ body, rules, large, biglede, leading, ...props }) => {
	const Body = styled.div`
	// ${tw`text-xl`};
	font-size: ${large ? '1.5rem' : '1.25rem'};
	


	p {
		${tw`mb-6`};
		line-height: ${leading === 'tight' ? '1.1' : '1.6'};
			&:last-of-type {
				${tw`mb-0`};
			}
		}

		h4 {
			${tw`text-2xl md:text-3xl mt-8 mb-4`};
		}

		.microcopy {
			${tw`text-sm`};
		}

	h2 {
		font-size: 40px;
		line-height: 1.1;
		margin-bottom: 5rem;
		position: relative;
		${rules &&
			`
		&:after {
			content: '';
			position: relative;
			width: 110px;
			border-bottom: 1px solid;
			display: block;
			margin-top: 30px;
		}`};
	}

	p.q {
		font-size: 26px;
		line-height: 1.33;
		margin-bottom: 10px;
		margin-top: 2rem;
`
	return body ? (
		<Body dangerouslySetInnerHTML={{ __html: body.content }} />
	) : null
}

export default Text

// p:first-child
// ${biglede ?
