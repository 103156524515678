import React from 'react'
import styled from 'styled-components'
import Link from '../Atoms/Link'
import TextDefault from '../Atoms/Text'
import Headline from '../Atoms/Headline'
import { FlexWrapper, ThreeQuartersCenter } from '../shared'

const BillboardCollected = ({
	body,
	headline,
	linkObject,
	style,
	children,
	spacious,
	...props
}) => {
	const BillboardWrapper = styled.div`
		${tw`text-center`};
	`

	return (
		<BillboardWrapper
			data-blocktheme={style}
			className={spacious ? 'py-24' : 'py-4'}
		>
			{headline && <Headline small>{headline}</Headline>}
			{body && (
				<FlexWrapper>
					<ThreeQuartersCenter>
						{<TextDefault body={body} />}
					</ThreeQuartersCenter>
				</FlexWrapper>
			)}
			{linkObject && (
				<div className="flex-none mt-6">
					<Link linkObject={linkObject} />
				</div>
			)}
			{children}
		</BillboardWrapper>
	)
}

export default BillboardCollected

// padding: ${(props.spacing = 'xl' ? '5rem 0' : '3rem 0')};
// spacing={'xl'}

// or
// spacious
// padding: ${spacious ? '40px 0' : '5px 0'};

// <pre>{JSON.stringify(linkObject, null, 2)}</pre>

// ${spacious ? `py-24` : `py-4`};
// padding: ${spacious ? '5rem 0' : '3rem 0'};
