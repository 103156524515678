import React from 'react'
import { graphql } from 'gatsby'
import RecentPress from '../Prebuilt/Press'

export default function Press(props) {
	const { id, style } = props?.matrixBuilder

	return <RecentPress style={style} />
}

export const query = graphql`
	fragment MatrixBuilderPressQuery on Craft_MatrixBuilderPress {
		id
		style
	}
`
