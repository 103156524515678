import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import GridCard from '../Atoms/GridCard'
import Modaling from '../Clean/Modaling'

const Box = styled.div`
	${tw`bg-yellow min-h-300 p-8`};
	pre {
		white-space: pre-wrap;
	}
`

const GridWrapper = styled.div`
	display: grid;
	// grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
	grid-column-gap: 20px;
	grid-row-gap: 20px;
	justify-items: stretch;
	align-items: stretch;
	position: relative;
	margin: 20px 0;
	// grid-auto-columns: minmax(200px, 1fr);
	// grid-auto-flow: column;

	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	.tg-item {
		${tw`min-h-300 bg-yellow-dark relative flex flex-col`};
		background: url('https://placehold.it/600x400');
	}

	.tg-1 {
		grid-area: 1 / 1 / 2 / 2;
	}

	.tg-2 {
		grid-area: 1 / 2 / 2 / 3;
	}

	.tg-3 {
		grid-area: 1 / 3 / 2 / 4;
	}

	.tg-4 {
		grid-area: 1 / 4 / 2 / 5;
	}

	.tg-5 {
		grid-area: 2 / 1 / 3 / 2;
	}

	.tg-6 {
		grid-area: 2 / 2 / 3 / 4;
	}

	.tg-7 {
		grid-area: 2 / 4 / 3 / 5;
	}

	.tg-8 {
		grid-area: 3 / 1 / 5 / 3;
	}

	.tg-9 {
		grid-area: 3 / 3 / 4 / 4;
	}

	.tg-10 {
		grid-area: 3 / 4 / 4 / 5;
	}

	.tg-11 {
		grid-area: 4 / 3 / 5 / 4;
	}

	.tg-12 {
		grid-area: 4 / 4 / 5 / 5;
	}

	.tg-13 {
		grid-area: 5 / 1 / 6 / 2;
	}

	.tg-14 {
		grid-area: 5 / 2 / 6 / 3;
	}

	.tg-15 {
		grid-area: 5 / 3 / 6 / 5;
	}
`

const GridListThree = ({ items, gridcount, gridrows, ...props }) => {
	const gridprefix = 'x'

	const RevWrapper = styled(GridWrapper)`
		grid-template-rows: repeat(${gridrows || 6}, 1fr);
	`

	const combineditems = items

	const itemone = {
		__typename: 'justimage',
		title: 'Stock One',
		uri: '',
		featuredImage: [
			{
				__typename: 'Craft_PrimaryAssetsVolume',
				url: 'https://pensa.imgix.net/primary/WObJFgY0zNo.jpg',
				focalPoint: {
					x: 0.5061,
					y: 0.6
				}
			}
		]
	}

	const itemtwo = {
		__typename: 'justimage',
		title: 'Stock Two',
		uri: null,
		featuredImage: [
			{
				__typename: 'Craft_PrimaryAssetsVolume',
				url: 'https://pensa.imgix.net/primary/mHpJToohk1Y.jpg',
				focalPoint: {
					x: 0.5061,
					y: 0.337
				}
			}
		]
	}

	// items.splice(5, 0, itemone)

	// items.splice(7, 0, itemtwo)

	combineditems.splice(5, 0, itemone)

	combineditems.splice(7, 0, itemtwo)

	// const newitems = items
	//   for (const item of newitems) {
	//     if (item.name === 'b') {
	//       return item
	//     }
	//   }

	// items.forEach((item, index) => {
	// 	if (index === 5) {
	// 		items.push('karl')
	// 	}
	// })

	// const elements = []
	// for (const i = 0; i < items.length; i++) {
	// 	// push the component to elements!
	// 	elements.push('karl')
	// }
	/* the for loop above is essentially the same as
        elements = arr.map( item => <Card value={ item } /> );
        The result is an array of four Card components. */
	// return (
	//     <div>
	//     {elements}
	//     </div>
	// );

	return (
		// <Box>
		// 	<pre>{JSON.stringify(combineditems, null, 2)}</pre>
		// </Box>

		<RevWrapper>
			{combineditems.map((entry, i) =>
				entry.__typename !== 'justimage' ? (
					<Modaling
						key={i}
						customClasses={
							'tg-item cursor-pointer hover-title w-full' + 'tg-' + (i + 1)
						}
						buttonLabel="the button label"
						title={entry.title}
						tagline={entry.jobTitle}
						modalTitle={entry.title}
						modalBody={entry.body}
						imageObject={entry.featuredImage}
						imgixParams={
							'?fm=jpg&auto=compress,enhance,format&fit=crop&h=400&w=400&sat=-100'
						}
					/>
				) : (
					<GridCard
						key={i}
						cardclasses={'tg-item ' + 'tg-' + (i + 1)}
						entry={entry}
						imageObject={entry.featuredImage}
						imgixParams="?fm=jpg&auto=compress,enhance,format&fit=crop&h=800&w=800"
					/>
				)
			)}
		</RevWrapper>
	)
}

export default GridListThree
