import React from 'react'
import { graphql } from 'gatsby'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import Link from '../Atoms/Link'

const Overwrap = styled.div`
	${tw`overflow-hidden relative flex items-center mb-6 mt-0 z-10`};
`

const VideoWrapper = styled.div`
	${tw`flex flex-col w-full relative m-0 p-0 leading-zero`};
	height: calc(100vh - 3rem);
`

const StyledReactPlayer = styled(ReactPlayer)`
// ${tw`absolute pin leading-zero`};
position: relative;
left: 0;
top: 0;
height: calc(100vh - 5rem);
width: 100%;
/* opacity: .62; */
overflow: hidden;
cursor: pointer;
object-fit: cover;
pointer-events: none;
`

export default function VideoHero(props) {
	const { video, headline, cta } = props?.matrixBuilder

	const linkObject = cta && cta[0].button.url ? cta[0] : null

	return (
		<Overwrap>
			<VideoWrapper>
				<StyledReactPlayer
					url={video}
					width="auto"
					height="100%"
					// width="100%"
					// height="calc(100vh - 3rem)"
					// style={{ height: calc(100vh - 3rem) }}
					playing
					muted
					loop
					playsinline
				/>
			</VideoWrapper>
			<div className="w-full z-20 leading-none h-full flex items-center absolute bg-black-30">
				<div className="flex flex-1 flex-col text-center px-6 md:px-10">
					{headline && (
						<h1 className="text-3xl md:text-5xl lg:text-6xl font-freight font-black py-8 text-white w-full">
							{headline}
						</h1>
					)}
					{linkObject && (
						<div className="inline-flex mx-auto">
							<Link linkObject={linkObject} />
						</div>
					)}
				</div>
			</div>
		</Overwrap>
	)
}

export const query = graphql`
	fragment MatrixBuilderVideoHeroQuery on Craft_MatrixBuilderVideoHero {
		id
		headline
		video
		cta {
			button {
				target
				entry {
					uri
					id
				}
				url
				text
				customText
			}
			buttonSize
			buttonIcon
			buttonStyle
		}
		hideOnMobile
	}
`
