import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { TitleLink } from '../shared'
import Headline from '../Atoms/Headline'

const CareersList = ({ style, ...props }) => {
	const data = useStaticQuery(
		graphql`
			query {
				craft {
					entries(section: [careers], limit: 12) {
						... on Craft_Careers {
							__typename
							title
							postDate
							externalLink {
								url
							}
						}
					}
				}
			}
		`
	)

	const Block = styled.div`
		${tw`p-8`};
	`

	// CAUTION THESE COLORS ARE NOT INHERITED FROM THEME??!!

	const JobLink = styled.a`
		${tw`block text-2xl py-4 md:py-6 text-black`};
		transition: all 200ms ease-in-out;

		h4 {
			${tw`mb-0`};
		}

		&:hover {
			h4 {
				${tw`text-orange`};
				transition: all 200ms ease-in-out;
			}
			p {
				${tw`opacity-50`};
				transition: all 200ms ease-in-out;
			}
		}
	`

	const jobs = data.craft.entries

	return (
		<Block data-blocktheme={style || 'light'}>
			{jobs.map(
				(job, i) =>
					job.externalLink && (
						<TitleLink
							key={i}
							href={job.externalLink.url}
							className="py-4 md:py-6"
						>
							<Headline level="h4" micro margin="0">
								{job.title}
							</Headline>
							<p className="text-xl">Brooklyn, NY</p>
						</TitleLink>
					)
			)}
		</Block>
	)
}

export default CareersList

// <pre>{JSON.stringify(jobs, null, 2)}</pre>
