import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import { IoMdPlayCircle } from 'react-icons/io'

export default function Text(props) {
	const { videoLink, videoPoster } = props?.matrixBuilder

	const StyledReactPlayer = styled(ReactPlayer)`
		${tw`absolute pin-t pin-l`};
	`

	const VideoWrap = styled.div`
		${tw`flex w-full mx-auto relative`};
		padding-top: 56.25%;
	`

	const VideoWrapPoster = styled.div`
		${tw`absolute pin bg-cover bg-no-repeat z-10`};
		background-image: url(${!videoPoster[0]
			? 'https://placehold.it/1280x720'
			: videoPoster[0].url +
			  '?auto=compress,format&w=1280&h=720&fit=crop&fp-x=' +
			  videoPoster[0].focalPoint.x +
			  '&fp-y=' +
			  videoPoster[0].focalPoint.y});
		background-position: ${!videoPoster[0]
			? '50% 50%'
			: videoPoster[0].focalPoint.x * 100 +
			  '% ' +
			  videoPoster[0].focalPoint.y * 100 +
			  '%'};
	`

	const IconHolder = styled.div`
		${tw`flex flex-grow w-full h-full flex-col items-center justify-center z-20 text-center`};
	`

	return (
		videoLink && (
			<VideoWrap>
				<VideoWrapPoster>
					<IconHolder>
						<IoMdPlayCircle color="#fff" size="5rem" />
					</IconHolder>
				</VideoWrapPoster>
				<StyledReactPlayer url={videoLink} width="100%" height="100%" />
			</VideoWrap>
		)
	)
}

export const query = graphql`
	fragment MatrixBuilderVideoQuery on Craft_MatrixBuilderVideo {
		videoLink
		videoPoster {
			url
			focalPoint {
				x
				y
			}
		}
	}
`
// light={
//   !videoPoster || !videoPoster.length ? true : videoPoster[0].url
// }
