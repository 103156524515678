import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { MdOpenInNew } from 'react-icons/md'
import { FiArrowRight } from 'react-icons/fi'
// import { IoIosArrowRoundForward } from 'react-icons/io'

const Button = styled(Link)`
	${tw`text-xl bg-transparent px-4 py-3 font-bold leading-none text-purple no-underline inline-flex items-center cursor-pointer outline-none align-middle relative appearance-none rounded-none`};
	letter-spacing: 1.5px;
	text-transform: uppercase;

	svg {
		// vertical-align: middle;
		margin-left: 10px;
	}

	&.btn-lg {
		${tw`text-2xl py-3 px-5`}
	}

	&.btn-sm {
		${tw`text-sm py-2 px-3`}
	}

	&.btn-ghost {
		${tw`p-0`}
	}

	&.btn-arrow {
		transition: background-color 0.5s cubic-bezier(0.19, 1, 0.22, 1);
		font-size: 1.1rem;
		width: auto;
		background: transparent;
		display: inline-block;
		text-align: center;
		overflow: hidden;
		margin-left: 0;

		&:not(.btn-ghost) {
			padding: 1.4rem 2.8rem;
			border: 2px solid #302e2a;
		}

		// &:hover {
		// 	span {
		// 		transform: translate3d(-1rem, 0, 0);
		// 	}
		// 	svg {
		// 		opacity: 1;
		// 		right: -2rem;
		// 	}
		// }
	}

	span {
		display: inline-block;
		position: relative;
		transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
		will-change: transform;
	}
	// svg {
	// 	position: absolute;
	// 	width: 1.1em;
	// 	right: 0px;
	// 	right: 0rem;
	// 	opacity: 0;
	// 	top: 0;
	// 	transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
	// 	will-change: right, opacity;
	// }

	// svg:not(:root) {
	// 	overflow: hidden;
	// }

	[data-blocktheme='light'] & {
		${tw`text-orange hover:text-orange-dark`};

		&.btn-outlined {
			${tw`text-black hover:text-orange border border-black hover:border-orange`}
		}

		&.btn-solid {
			${tw`text-white hover:text-white bg-black hover:bg-orange`}
		}
	}

	[data-blocktheme='black'] & {
		${tw`text-white hover:text-orange`};

		&.btn-outlined {
			${tw`text-white hover:text-orange border border-white hover:border-orange`}
		}

		&.btn-solid {
			${tw`bg-white hover:bg-grey text-black hover:text-blue`}
		}
	}

	[data-blocktheme='primary'] & {
		${tw`text-white hover:text-black`};

		&.btn-outlined {
			${tw`text-white hover:text-black border border-white hover:border-black`}
		}

		&.btn-solid {
			${tw`text-orange hover:text-white bg-white hover:bg-orange-dark`}
		}
	}
`

const AltButton = styled(Button)`
	${tw``};
`

// const OutlineButton = styled(Button)`
// 	${tw`border border-blue-darker`};
// `

// const GhostButton = styled(Button)`
// 	${tw`bg-white opacity-50`};
// `

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
const StyledLink = ({
	children,
	linkObject,
	activeClassName,
	icon,
	...other
}) => {
	const buttonSize = linkObject ? linkObject.buttonSize : 'lg'
	const buttonStyle = linkObject ? linkObject.buttonStyle : 'outlined'
	const buttonIcon = linkObject ? linkObject.buttonIcon : 'arrow'

	const buttonSpecs =
		'btn-' +
		buttonSize +
		' btn-' +
		buttonStyle +
		' btn-' +
		buttonIcon +
		' transition-duration-200 transition-timing-ease-in'
	// const to = linkObject.entry

	// Tailor the following test to your environment.
	// This example assumes that any internal link (intended for Gatsby)
	// will start with exactly one slash, and that anything else is external.
	// const internal = /^\/(?!\/)/.test(to)

	// Use Gatsby Link for internal links, and <a> for others

	return (
		<Button
			as="a"
			href={linkObject ? linkObject.button.url : null}
			className={buttonSpecs}
			target={linkObject ? linkObject.button.target : null}
			{...other}
		>
			{children}
		</Button>
	)
}

export default StyledLink

// const buttonSize = linkObject ? linkObject.buttonSize : 'lg'
// const buttonStyle = linkObject ? linkObject.buttonStyle : 'outlined'
// const buttonIcon = linkObject ? linkObject.buttonIcon : 'arrow'

// const buttonSize = linkObject ? linkObject.buttonSize : 'lg'
// const buttonStyle = linkObject ? linkObject.buttonStyle : 'outlined'
// const buttonIcon = linkObject ? linkObject.buttonIcon : 'arrow'

// {icon ? <FiArrowRight /> : null}
// {other.arrow && <FiArrowRight />}
