import React from 'react'
import { graphql } from 'gatsby'
import Instacomponent from '../Prebuilt/Instacomponent'

export default function Instagram(props) {
	const { id, style } = props?.matrixBuilder

	return <Instacomponent style={style} />
}

export const query = graphql`
	fragment MatrixBuilderInstagramQuery on Craft_MatrixBuilderInstagram {
		id
		style
	}
`
