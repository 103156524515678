import React, { Component } from 'react'
import { graphql } from 'gatsby'

import * as matrixBuilderComponents from '../MatrixBuilderComponents'

const componentNamePattern = 'Craft_MatrixBuilder'

function getComponent(__typename) {
	const componentName = __typename.replace(componentNamePattern, '')
	return matrixBuilderComponents[componentName] || false
}

export default class MatrixBuilder extends Component {
	render() {
		const { matrixBuilder } = this.props

		return (
			<div>
				{matrixBuilder.map((el, i) => {
					const MatrixBuilderComponent = getComponent(el.__typename)
					return MatrixBuilderComponent ? (
						<MatrixBuilderComponent
							matrixBuilder={el}
							key={el.__typename + i}
						/>
					) : null
				})}
			</div>
		)
	}
}

export const query = graphql`
	fragment MatrixBuilderQuery on Craft_MatrixBuilderUnion {
		__typename
		... on Craft_MatrixBuilderCopy {
			...MatrixBuilderCopyQuery
		}
		... on Craft_MatrixBuilderImage {
			...MatrixBuilderImageQuery
		}
		... on Craft_MatrixBuilderImageTextCombo {
			...MatrixBuilderImageTextComboQuery
		}
		... on Craft_MatrixBuilderBillboard {
			...MatrixBuilderBillboardQuery
		}
		... on Craft_MatrixBuilderWorkSelections {
			...MatrixBuilderWorkSelectionsQuery
		}
		... on Craft_MatrixBuilderTestimonial {
			...MatrixBuilderTestimonialQuery
		}
		... on Craft_MatrixBuilderVideo {
			...MatrixBuilderVideoQuery
		}
		... on Craft_MatrixBuilderImageArray {
			...MatrixBuilderImageArrayQuery
		}
		... on Craft_MatrixBuilderWork {
			...MatrixBuilderWorkQuery
		}
		... on Craft_MatrixBuilderPeople {
			...MatrixBuilderPeopleQuery
		}
		... on Craft_MatrixBuilderMap {
			...MatrixBuilderMapQuery
		}
		... on Craft_MatrixBuilderCareers {
			...MatrixBuilderCareersQuery
		}
		... on Craft_MatrixBuilderInstagram {
			...MatrixBuilderInstagramQuery
		}
		... on Craft_MatrixBuilderPress {
			...MatrixBuilderPressQuery
		}
		... on Craft_MatrixBuilderCallToAction {
			...MatrixBuilderCallToActionQuery
		}
		... on Craft_MatrixBuilderVideoHero {
			...MatrixBuilderVideoHeroQuery
		}
	}
`
