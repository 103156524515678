import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import TextDefault from '../Atoms/Text'
import Image from '../Atoms/Image'
import Link from '../Atoms/Link'
import Servicer from '../Clean/ServiceList'
import Headline from '../Atoms/Headline'

export default function ImageTextComboBlock(props) {
	const {
		body,
		image,
		cta,
		headline,
		serviceSelector,
		style,
		flipped
	} = props?.matrixBuilder

	const Blockwrapper = styled.div`
		${tw`flex flex-wrap blocktheme`};
	`

	// const Headering = styled.h3`
	// 	${tw`text-5xl leading-none mb-4`};
	// `

	const Bgimagebox = styled.div`
		${tw`w-full md:w-1/2 leading-zero bg-cover bg-no-repeat`};
		background-image: url(${!image[0]
			? 'https://placehold.it/600x600'
			: image[0].url +
			  '?auto=compress,format&w=600&h=600&fit=crop&fp-x=' +
			  image[0].focalPoint.x +
			  '&fp-y=' +
			  image[0].focalPoint.y});
		background-position: ${!image[0]
			? '50% 50%'
			: image[0].focalPoint.x * 100 + '% ' + image[0].focalPoint.y * 100 + '%'};
	`

	return (
		<Blockwrapper
			data-blocktheme={style}
			className={flipped ? ' md:flex-row-reverse' : null}
		>
			<div className="flex flex-1 w-full md:w-1/2">
				<div className="flex flex-grow">
					<div className="w-full md:w-3/4 p-6 md:p-10 flex flex-1 flex-col mx-auto leading-normal">
						<Headline level="h3" small>
							{headline}
						</Headline>
						{!serviceSelector || !serviceSelector.length ? null : (
							<div className="my-4">
								<Servicer serviceSelector={serviceSelector} large />
							</div>
						)}

						{body && <TextDefault body={body} large />}
						{!cta[0].button.url ? null : (
							<div className="flex-none mt-6">
								<Link linkObject={cta[0]} />
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="w-full md:w-1/2 leading-zero">
				{!image[0] ? null : (
					<Image
						imageObject={image[0]}
						avatarStyle={false}
						aspectRatio="square"
						placeholder="https://placehold.it/73x73"
						imgixParams="?fit=crop&h=738&w=738"
						imageClasses="w-full"
						lazyClasses="aspect-ratio-square"
					/>
				)}
			</div>
		</Blockwrapper>
	)
}

export const query = graphql`
	fragment MatrixBuilderImageTextComboQuery on Craft_MatrixBuilderImageTextCombo {
		id
		headline
		body {
			content
		}
		image {
			url
			focalPoint {
				x
				y
			}
		}
		imgixParams {
			focalPointZoom
			sat
			blur
		}
		cta {
			button {
				target
				entry {
					uri
					id
				}
				url
				text
				customText
			}
			buttonSize
			buttonIcon
			buttonStyle
		}
		serviceSelector {
			id
			title
			icon
		}
		style
		flipped
	}
`

// : '?&auto=compress,format&blend=0000FF&inv=true')
// <Bgimagebox />
