import React from 'react'
import styled from 'styled-components'
import InlineSVG from 'svg-inline-react'

// props == bordered, sizing, color

const Servicer = ({ serviceSelector, large, rules }) => {
	const IconLineWrapper = styled.div`
		${tw`block w-full`};
		margin-bottom: ${large ? '.75rem' : '.25rem'};
		// background: ${large ? 'red' : 'cyan'};
		// &:first-of-type {
		// 	${tw`border-b mb-4`};
		// 
	}
	`
	const IconLine = styled.div`
		${tw`inline-flex items-center`};
		font-size: ${large ? '1.25rem' : '.875rem'};
		span {
			${tw`ml-2 font-medium`};
			// text-sm
		}
	`

	const IconWrapper = styled.div`
		${tw`inline-block opacity-50`};
		svg {
			${tw`w-8 stroke-current`};
		};
		
	}
	`

	return serviceSelector.map((selector, i) => (
		<IconLineWrapper key={i}>
			<IconLine>
				<IconWrapper>
					<InlineSVG src={selector.icon} />
				</IconWrapper>
				<span>{selector.title}</span>
			</IconLine>
		</IconLineWrapper>
	))
}

export default Servicer
