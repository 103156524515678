import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import styled from 'styled-components'
import TextDefault from '../Atoms/Text'
import '../../styles/modal-too.css'

const ModalCard = styled.div`
	${tw`flex flex-grow w-full h-full`};
	background: white;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	text-decoration: none;
	color: #444;
	position: relative;
	top: 0;
	transition: all 0.1s ease-in;
	&:hover {
		top: -2px;
		box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
	}
`

const Thumb = styled.div`
	${tw`absolute pin bg-cover bg-center bg-no-repeat`};
`

const Article = styled.div`
	${tw`flex flex-1 flex-col text-center justify-center z-10  p-6 opacity-0 text-white w-full`};
	transition: 200ms all ease-in-out;
	background: rgba(38, 38, 38, 0.7);
	.hover-title & {
		${tw`hover:opacity-100`};
	}
`

const Headline = styled.h2`
	${tw`m-0 text-3xl md:4xl text-white w-full`};
`

const Tagline = styled.h4`
	${tw`mt-3 text-xl md:2xl text-white opacity-75`};
`

const PeopleButton = styled.button`
	${tw`absolute pin z-20 bg-transparent w-full appearance-none focus:transparent outline-none focus:outline-none m-0 text-3xl md:4xl text-white opacity-0`};
	&:hover {
		${tw`opacity-100`};
		transition: 200ms all ease-in-out;

		&:after {
			${tw`absolute pin`};
			content: '';
			transition: 200ms all ease-in-out;
			background: rgba(38, 38, 38, 0.7);
			z-index: -1;
		}
	}
`

class ModalExample extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			modal: false
		}

		this.toggle = this.toggle.bind(this)
	}

	toggle() {
		this.setState(prevState => ({
			modal: !prevState.modal
		}))
	}

	render() {
		const titlechunk = (
			<Article>
				<Headline>{this.props.title}</Headline>
				{this.props.tagline ? <Tagline>{this.props.tagline}</Tagline> : null}
			</Article>
		)
		return (
			<div>
				<ModalCard className={this.props.customClasses}>
					{titlechunk}
					<PeopleButton onClick={this.toggle}>{this.props.title}</PeopleButton>
					<Thumb
						style={{
							backgroundImage:
								!this.props.imageObject || !this.props.imageObject.length
									? 'url(https://placehold.it/600x400)'
									: 'url(' +
									  this.props.imageObject[0].url +
									  (this.props.imgixParams ||
											'?fm=jpg&auto=compress,enhance,format&fit=crop&max-w=600&max-h=400') +
									  ')',
							backgroundPosition:
								!this.props.imageObject || !this.props.imageObject.length
									? 'center top'
									: this.props.imageObject[0].focalPoint.x * 100 +
									  '%' +
									  ' ' +
									  this.props.imageObject[0].focalPoint.y * 100 +
									  '%'
						}}
					/>
				</ModalCard>
				<Modal
					isOpen={this.state.modal}
					toggle={this.toggle}
					className={this.props.className}
				>
					<ModalHeader toggle={this.toggle} className="items-center">
						{this.props.modalTitle || 'A modal title'}
					</ModalHeader>
					<ModalBody>
						{this.props.modalBody && (
							<TextDefault body={this.props.modalBody} large />
						)}
					</ModalBody>
				</Modal>
			</div>
		)
	}
}

export default ModalExample

// <ModalFooter>
//   <Button color="primary" onClick={this.toggle}>
//     Do Something
//   </Button>{' '}
//   <Button color="secondary" onClick={this.toggle}>
//     Cancel
//   </Button>
// </ModalFooter>

// <Button color="danger" onClick={this.toggle}>
//   {this.props.buttonLabel}
// </Button>
