import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { MdOpenInNew } from 'react-icons/md'
import { TwoThirdsCenter, TitleLink } from '../shared'
import Headline from '../Atoms/Headline'

// NOTE EXTRA WIDE GUTTER FOR LEGIBLE COLS

const FlexWrapper = styled.div`
	${tw`flex flex-wrap sm:-mx-6`};
`

const FlexCard = styled.div`
	${tw`flex w-full sm:w-1/2 sm:px-6 mb-16 group`};
`

const PressTitle = styled.p`
	${tw`text-xl mt-4 leading-none no-underline flex items-center cursor-pointer outline-none align-middle relative mb-0 text-grey-darkest opacity-100`};

	svg {
		// vertical-align: middle;
		margin-left: 10px;
	}
`

const RecentPress = ({ style, ...props }) => {
	const data = useStaticQuery(
		graphql`
			query {
				craft {
					entries(section: press, limit: 4) {
						title
						... on Craft_Press {
							pressSelector {
								title
							}
							externalLink {
								url
								customText
							}
						}
					}
				}
			}
		`
	)

	const Box = styled.div`
		${tw`py-16`};
	`

	return (
		<Box data-blocktheme={style || 'light'}>
			<FlexWrapper>
				<TwoThirdsCenter>
					<Headline small>In the News</Headline>
					<FlexWrapper>
						{data.craft.entries.map((entry, i) => (
							<FlexCard key={i}>
								<TitleLink href={entry.externalLink.url}>
									<Headline level="h4" margin="0" micro>
										{entry.externalLink.customText || entry.title}
									</Headline>
									{entry.pressSelector[0] && (
										<PressTitle>
											{entry.pressSelector[0].title} <MdOpenInNew />
										</PressTitle>
									)}
								</TitleLink>
							</FlexCard>
						))}
					</FlexWrapper>
				</TwoThirdsCenter>
			</FlexWrapper>
		</Box>
	)
}

export default RecentPress

// <pre>{JSON.stringify(data.craft.entries, null, 2)}</pre>
