import React from 'react'
import { graphql } from 'gatsby'
import BillboardCollected from '../Clean/BillboardCollected'

const Billboard = props => {
	const { headline, body, cta, style } = props?.matrixBuilder

	return (
		<BillboardCollected
			spacious
			headline={headline}
			body={body}
			linkObject={cta && cta[0].button.url ? cta[0] : null}
			style={style}
		/>
	)
}

export default Billboard

export const query = graphql`
	fragment MatrixBuilderBillboardQuery on Craft_MatrixBuilderBillboard {
		id
		headline
		body {
			content
		}
		cta {
			button {
				target
				entry {
					uri
					id
				}
				url
				text
				customText
			}
			buttonSize
			buttonIcon
			buttonStyle
		}
		style
	}
`
