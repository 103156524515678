import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import TextDefault from '../Atoms/Text'
import {
	OuterWidth,
	ThreeQuartersCenter,
	FlexWrapperPadded
} from '../../components/shared'

export default function Text(props) {
	const { body, style, index } = props?.matrixBuilder

	return (
		body && (
			<FlexWrapperPadded data-blocktheme={style}>
				<OuterWidth>
					<ThreeQuartersCenter>
						{<TextDefault body={body} rules />}
					</ThreeQuartersCenter>
				</OuterWidth>
			</FlexWrapperPadded>
		)
	)
}

export const query = graphql`
	fragment MatrixBuilderCopyQuery on Craft_MatrixBuilderCopy {
		id
		body {
			content
		}
		style
	}
`
