import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import ComposedImage from '../Atoms/Image'

const FlexWrap = styled.div`
	${tw`flex flex-wrap w-full`};
`
const ImageSquare = styled.div`
	${tw`w-full md:w-1/2 leading-zero`};
`

export default function Image(props) {
	const { images } = props?.matrixBuilder

	return !images ? null : (
		<FlexWrap>
			{images.map((image, i) => (
				<ImageSquare key={i}>
					<ComposedImage
						imageObject={image}
						avatarStyle={false}
						aspectRatio="square"
						placeholder={'https://placehold.it/600x600'}
						imgixParams={'?fit=crop&h=600&w=600'}
						imageClasses={'w-full'}
						lazyClasses={'aspect-ratio-square'}
					/>
				</ImageSquare>
			))}
		</FlexWrap>
	)
}

export const query = graphql`
	fragment MatrixBuilderImageArrayQuery on Craft_MatrixBuilderImageArray {
		images {
			url
			focalPoint {
				x
				y
			}
		}
	}
`
