import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Team from '../Prebuilt/Team'
import { FlexWrapperPadded, Full, OuterWidth } from '../shared'
import Button from '../Atoms/Button'

export default function People(props) {
	const { id, style } = props?.matrixBuilder

	return (
		<FlexWrapperPadded data-blocktheme={style || 'primary'}>
			<Full>
				<OuterWidth>
					<Team style={style} />
					<Button to="/contact" icon="arrow">
						Apply to work at Pensa
					</Button>
				</OuterWidth>
			</Full>
		</FlexWrapperPadded>
	)
}

export const query = graphql`
	fragment MatrixBuilderPeopleQuery on Craft_MatrixBuilderPeople {
		id
		style
	}
`
// <Button to="/contact" icon="arrow">
// <Button to="/contact" arrow>
// import { FiArrowRight } from 'react-icons/fi'
