import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import SimpleMap from '../Prebuilt/Map'

const FlexWrap = styled.div`
	${tw`flex flex-wrap -mx-3 py-16`};
`

const ThreeQuarter = styled.div`
	${tw`w-full md:w-3/4 px-3 mx-auto`};
`

const Box = styled.div`
	padding: 20px;
	background: lemonchiffon;

	pre {
		white-space: pre-wrap;
	}
`

export default function Map(props) {
	const { id } = props?.matrixBuilder

	return (
		<FlexWrap>
			<ThreeQuarter>
				<Box data-blocktheme="light">
					<pre>MAP - {JSON.stringify(id, null, 2)}</pre>
				</Box>
			</ThreeQuarter>
		</FlexWrap>
	)
}

export const query = graphql`
	fragment MatrixBuilderMapQuery on Craft_MatrixBuilderMap {
		id
	}
`
// <SimpleMap />
