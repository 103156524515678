import React from 'react'
import { graphql } from 'gatsby'
import Working from '../Prebuilt/Work'
import { FlexWrapperPadded, Full } from '../shared'

export default function Work(props) {
	const { id, style } = props?.matrixBuilder

	return (
		<FlexWrapperPadded data-blocktheme={style || 'primary'}>
			<Full>
				<Working style={style} />
			</Full>
		</FlexWrapperPadded>
	)
}

export const query = graphql`
	fragment MatrixBuilderWorkQuery on Craft_MatrixBuilderWork {
		id
		style
	}
`
