import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const GridCard = ({
	entry,
	imageObject,
	imageUrl,
	imgixParams,
	cardclasses,
	...props
}) => {
	const Card = styled(Link)`
		${tw`flex flex-grow w-full h-full`};
		background: ${props.background || 'white'};
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
		text-decoration: none;
		color: #444;
		position: relative;
		top: 0;
		transition: all 0.1s ease-in;
		&:not(.no-hover) {
			:hover {
				top: -2px;
				box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
			}
		}
	`

	const Thumb = styled.div`
		${tw`absolute pin bg-cover bg-center bg-no-repeat`};
	`

	const Article = styled.div`
		${tw`flex flex-1 flex-col text-center justify-center z-10  p-4 md:p-6 opacity-0 text-white overflow-x-hidden bg-`};
		transition: 200ms all ease-in-out;
		background: rgba(38, 38, 38, 0.7);
		.hover-title & {
			${tw`hover:opacity-100`};
		}
	`

	const Headline = styled.h2`
		${tw`m-0 text-3xl md:4xl text-white`};
	`

	const Tagline = styled.h4`
		${tw`mt-3 text-xl md:2xl text-white opacity-75`};
	`

	const bgimage = (
		<Thumb
			style={{
				backgroundImage:
					!imageObject || !imageObject.length
						? 'url(https://placehold.it/600x400)'
						: 'url(' +
						  imageObject[0].url +
						  (imgixParams ||
								'?fm=jpg&auto=compress,enhance,format&fit=crop&max-w=600&max-h=400') +
						  ')',
				backgroundPosition:
					!imageObject || !imageObject.length
						? 'center top'
						: imageObject[0].focalPoint.x * 100 +
						  '%' +
						  ' ' +
						  imageObject[0].focalPoint.y * 100 +
						  '%'
			}}
		/>
	)

	const titlechunk = (
		<Article>
			<Headline>{entry.title}</Headline>
			{entry.headline ? <Tagline>{entry.headline}</Tagline> : null}
		</Article>
	)

	return entry.__typename === 'justimage' ? (
		<Card as="div" className={cardclasses + ' cursor-default no-hover'}>
			{titlechunk}
			{bgimage}
		</Card>
	) : (
		<Card
			to={`/${entry.uri}`}
			className={cardclasses + ' cursor-pointer hover-title'}
		>
			{titlechunk}
			{bgimage}
		</Card>
	)
}

export default GridCard

// ${props => (props.transparent ? 'transparent' : 'white')};
