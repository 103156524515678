import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GridList from '../Clean/GridList'

const Work = ({ style, ...props }) => {
	const data = useStaticQuery(
		graphql`
			query {
				craft {
					entries(section: [work], limit: null) {
						... on Craft_Work {
							__typename
							title
							uri
							primaryThumb {
								url
								focalPoint {
									x
									y
								}
							}
							headline
						}
					}
				}
			}
		`
	)

	return <GridList items={data.craft.entries} />
}

export default Work

// data-blocktheme={style || 'light'}>
