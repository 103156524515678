import React from 'react'
import { graphql } from 'gatsby'
import ComposedImage from '../Atoms/Image'

export default function Image(props) {
	const { image } = props?.matrixBuilder

	return !image[0] ? null : (
		<ComposedImage
			imageObject={image[0]}
			avatarStyle={false}
			aspectRatio={'16x9'}
			placeholder={'https://placehold.it/1312x738'}
			imgixParams={'?fit=crop&h=738&w=1312'}
			imageClasses={'w-full'}
			lazyClasses={'aspect-ratio-16/9'}
		/>
	)
}

export const query = graphql`
	fragment MatrixBuilderImageQuery on Craft_MatrixBuilderImage {
		image {
			url
			focalPoint {
				x
				y
			}
		}
		imgixParams {
			focalPointZoom
			sat
			blur
		}
	}
`
