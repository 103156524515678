import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GridListTeam from '../Clean/GridListTeam'

const Team = ({ style, ...props }) => {
	const data = useStaticQuery(
		graphql`
			query {
				craft {
					entries(section: [team], limit: null) {
						... on Craft_Team {
							__typename
							title
							jobTitle
							uri
							body {
								content
							}
							featuredImage {
								url
								focalPoint {
									x
									y
								}
							}
						}
					}
				}
			}
		`
	)

	return (
		<GridListTeam
			items={data.craft.entries}
			gridrows={5}
			gridcount={data.craft.entries.length}
		/>
	)
}

export default Team
// data-blocktheme={style || 'light'}>
